import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Template from '../templates/confirmation-template'

const Confirmation = (props) => <Template {...props} />

Confirmation.propTypes = {
  data: PropTypes.object.isRequired
}

export default Confirmation

export const pageQuery = graphql`
  query confirmationEnQuery {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
  }
`